.tournaments-by-month-div {
    /* text colouring*/
    color: ivory;
    width: 100%;
}

p, h1, a {
    color: ivory;
}

.month-heading-div {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.month-heading-collapse-btn {
    cursor: pointer;
    transform: translateY(-0.05em);
}

.month-heading {
    margin: 0;
    font-size: 1.5em;
    width: 6.7em;
    text-align: left;
    padding: 2vw 2.5vw 2vw 1.5vw;
}