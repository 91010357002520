p {
    margin: 0;
}

.tournaments-outer-div {
    padding: 0 8vw;
    height: 20vh;
}

.tournaments-inner-div {
    background-color: #3c3f49;
    height: 100%;
    border: 2px solid rgb(33 33 35);
    border-radius: 0;
}

.tournaments-inner-div:hover {
    background-color: #515466;
}


hr.solid {
    border-top: 3px solid #bbb;
}

hr {
    margin: 0;
}


.tournament-data {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 100%;
    width: 100%;
    text-align: center;
}


.vertical-line {
    opacity: 0.6;
    border: 1px solid ivory;
    height: 50% !important;
}


/* main tournament info */
.main-tournament-info-div {
    width: 17.5%;
    height: 100%;
    display: flex;
    flex-direction: column;
    float: left;
    align-items: baseline;
    padding-left: 2.5%;
    text-align: left;
}

.main-tournament-info-item {
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: center;
}

.prize-pool-div {
    height: 20%;
    max-height: 20%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.tournament-format-div {
    height: 20%;
    max-height: 20%;
}

.main-tournament-spacer-div {
    height: 10%;
    max-height: 10%;
}

.tournament-rules-div {
    height: 25%;
    max-height: 25%;
    font-size: 0.8em;
}

/* empty spacer div*/
.spacer-div {
    width: 57.5%;
}

.stream-link-div {
    margin-left: auto;
    width: 10%;
    min-width: 100px;
    height: auto;
    padding-right: 30px;
}

/* all tournament row items */
.tournament-data-item {
    display: inline-block;
    min-width: 56px;
}

.tournament-name-div {
    height: 25%;
    max-height: 25%;
}


.trophy-img {
    height: 1.1em;
    padding-right: 0.3em;
}

.monitor-img {
    height: 4em;
}

.stream-img {
    height: auto;
    width: 100%;
}

.tournament-name {
    padding-top: 0.3em;
    font-size: 1.3em;
    white-space: nowrap;
}

.tournament-format {
    font-size: 0.8em;
    white-space: nowrap;
}