.header-div {
    z-index: 1;
    height: 8vh;
    width: 100%;

    background-color: hsl(222, 14%, 10%);
    transition: all .5s ease-in;
}

#tth-img-link {
    width: 8vh;
    padding: 0;
    margin-left: 10px;
    margin-right: 0;

    position: relative;
}

#tth-img-link:hover{
    background-color: transparent;
}

#tth-img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.tth-img {
    height: 90%;
    width: 90%;
}

.header-div button {
    cursor: pointer;
    font-size: 1.5em;
}

ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
}

.header-list{
    height: 100%;
    width: 100%;
}

.header-div .right{
    float: right;
}

.header-div li {
    float: left;
    height: 100%;
}

.header-div li button {
    height: 100%;
    display: table;
    color: white;
    text-align: center;
    padding: 14px 16px;
    text-decoration: none;
}

.header-div li p {
    height: 100%;
    text-align: center;
    vertical-align: middle;
    display: table-cell;

    text-transform: uppercase;
    text-decoration: none;
    font-size: 18px;
    letter-spacing: 1.24px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 200;
    font-style: normal;
}

.header-div li button:hover:not(.active) {
    background-color: #515466;
    border-radius: 5px;
}

.header-div button{
    /*remove all button style and fit div*/
    height: 100%;
    width: 100%;
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
}