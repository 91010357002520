.player-info-div{
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    height: 10%;
    text-align: center;
}

.player-image{
    padding: 0.5em;
    height: 100px;
    width: auto;
}

.player-gamertag-heading {
    padding-right: 1em;
    color: ivory;
    margin-left: auto;
}